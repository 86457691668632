import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

import contactStyles from './contact.module.scss'

const ContactPage = () => {
    return (
            <Layout>
                <Head title="Contact Us" />
                <div className={contactStyles.container}>
                <h1>Contact Us</h1>
                <p>You can write to the Parish on <a href="https://www.facebook.com/immaculatemarymty/" target="_blank">Facebook</a> or send us a message by filling out the form below! We look forward to hearing from you.</p>
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" className={contactStyles.contactForm}>
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <label className={contactStyles.label}>Full Name
                        <input className={contactStyles.formInput} type="text" name="name" id="name" />
                    </label>
                    <label className={contactStyles.label}>Email
                        <input className={contactStyles.formInput} type="email" name="email" id="email" />
                    </label>
                    <label className={contactStyles.label}>Subject
                        <input className={contactStyles.formInput} type="text" name="subject" id="subject" />
                    </label>
                    <label className={contactStyles.label}>Message
                        <textarea className={contactStyles.formInput} name="message" id="message" rows="5"/>
                    </label>
                    <div className={contactStyles.suscribe}>
                    <input className={contactStyles.formCheckbox} type="checkbox" id="suscribe" name="suscribe" value="yes" />
                    <label className={contactStyles.suscribeLabel}>Subscribe to our parish email list</label>
                    </div>
                    <button className={contactStyles.formSubmit} type="submit">Send</button>
                </form>
                </div>
            </Layout>
    )
}

export default ContactPage